<template>
	<div class="main">
		<div class="Day" v-if="days">
			<p>{{ days }}</p>
			<p>{{$VCountdown("天")}}</p>
		</div>
		<div class="hours">
			<p>{{ hours }}</p>
			<p>{{$VCountdown("时")}}</p>
		</div>
		<div class="minutes">
			<p>{{minutes}}</p>
			<p>{{$VCountdown("分")}}</p>
		</div>
		<div class="seconds">
			<p>{{seconds}}</p>
			<p>{{$VCountdown("秒")}}</p>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'
	
    /* viai18n-disable */
    import $VCountdownMessages from "./Countdown.messages.json";
  const $defaultObject = {
		name: 'Name',
		data() {
			return {

				times: 0,
				days: '00',
				hours: '00',
				minutes: '00',
				seconds: '00',
				timeOb: '',
			}
		},
		props: {
			timespans: {
				type: Number,
				default: 0
			},
			nowTime: {
				type: [String, Number],
				default: ''
			},
			endTime: {
				type: [String, Number],
				default: ''
			},
			reward_interval: {
				type: [String, Number],
				default: ''
			},
		},
		mounted() {
			console.log('times', this.timespans)
			if(this.timespans<=0)return
			if (this.timespans) {
				this.times = this.timespans;
			} else {
				let nowTime = new Date(this.nowTime);
				let endTime = new Date(this.endTime);
				this.times = (this.reward_interval * 1000) - (endTime.getTime() - nowTime.getTime());
				this.countdowm()
			}


		},
		watch: {
			// nowTime() {
			// 	clearInterval(this.timeOb)
			// 	this.countdowm()
			// },
			// endTime() {
			// 	clearInterval(this.timeOb)
			// 	this.countdowm()
			// },
			timespans(val) {
				if (val <= 0) return
				this.times = this.timespans;
				clearInterval(this.timeOb)
				this.countdowm()
			},
			reward_interval() {
				clearInterval(this.timeOb)
				this.countdowm()
			}
		},
		beforeDestroy() {
			clearInterval(this.timeOb)
		},
		methods: {
			countdowm() {
				this.timeOb = setInterval(() => {
					this.times = this.times - 1000;

					let t = this.times;

					if (t >= 0) {
						let day = Math.floor(t / 86400000);
						let hour = Math.floor((t / 3600000) % 24);
						let min = Math.floor((t / 60000) % 60);
						let sec = Math.floor((t / 1000) % 60);
						hour = hour < 10 ? "0" + hour : hour;
						min = min < 10 ? "0" + min : min;
						sec = sec < 10 ? "0" + sec : sec;

						this.days = day
						this.hours = hour
						this.minutes = min
						this.seconds = sec
					} else {
						clearInterval(this.timeOb);
						this.$emit('onClose')
						this.content = this.endText;
						this.$parent.init();
					}
				}, 1000);
			}
		}
	}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VCountdown(key,origin){
      const messages = $VCountdownMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VCountdown(key,origin){
      const messages = $VCountdownMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>

<style lang="scss" scoped>
	.main {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 30px 0 40px;

		div {
			width: 75px;
			border: 1px solid #D2D2D2;
			border-radius: 8px;
			overflow: hidden;

			p {
				display: inline-block;
				padding: 0;
				margin: 0;
			}

			p:nth-of-type(1) {
				font-size: 42px;
				color: #33C4F2;
				font-weight: bold;
				margin-top: -5px;
			}

			p:nth-of-type(2) {
				padding-bottom: 3px;
				width: 100%;
				margin-top: -5px;
				border-top: 1px solid #D2D2D2;
				font-size: 16px;
				color: #fff;
			}
		}

		div+div {
			margin-left: 20px;
		}

	}
</style>