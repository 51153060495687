<template>
	<div class="faucet">
		<Header></Header>
		<div class="faucet-box md:px-12">
			<Breadcrumb :routes="routes"></Breadcrumb>
			<div class="supply-title">
				<div class="title">
					<h1>WOD</h1>
					<span>&nbsp;Network</span>
				</div>
			</div>
			<div class="faucetCard-box">
				<faucetCard></faucetCard>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Breadcrumb from '@/components/common/Breadcrumb.vue'
	import faucetCard from '@/components/faucetCard/faucetCard.vue'
	import { mapState } from 'vuex'
	export default {
		name: 'Faucet',
		components: {
			Header,
			Breadcrumb,
			faucetCard
		},
		data() {
			return {
				routes: [{
						path: '/',
						breadcrumbName: 'Home',
					},
					{
						path: '/Faucet',
						breadcrumbName: 'Faucet',
					},
				],
			}
		},
		computed: {
			...mapState(['isConnect', 'address']),
		},
		mounted() {

		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.faucet {
		position: relative;
		background-color: #000000;
		color: #ffffff;

		.faucet-box {
			padding: 14px 75px;

			@screen md {
				padding-top: 55px;
			}
		}

		.supply-title {
			.title {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 12px;
				margin-bottom: 26px;

				@screen md {
					display: block;
					margin-top: 32px;
					margin-bottom: 16px;
				}

				h1 {
					margin: 0;
					font-size: 64px;
					color: #fff;
					font-weight: 700;

					@screen md {
						font-size: 30px;
						text-align: center;
					}
				}

				span {
					font-size: 64px;
					font-weight: 800;
					background-image: linear-gradient(to right, #33C4F2, #1F35FD);
					-webkit-background-clip: text;
					background-clip: text;
					color: transparent;
					display: inline-block;

					@screen md {
						display: inline-block;
						width: 100%;
						font-size: 30px;
						text-align: center;
					}
				}
			}

			p {
				text-align: center;
				color: rgba(255, 255, 255, 0.60);
				font-size: 20px;
				font-weight: 700;
				font-style: normal;
				line-height: 32.5px;
				/* 162.5% */
				letter-spacing: 2px;
				margin: 0;

				@screen md {
					font-size: 10px;
					line-height: 18px;
					letter-spacing: 0.1px;
				}
			}
		}

		.faucetCard-box {
			display: flex;
			justify-content: center;
		}
	}
</style>