<template>
	<div class="faucetCard">
		<p class="title">Faucet</p>
		<p class="image-box">
			<img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/faucetIcon1.jpg" alt="" srcset="" />
		</p>
		<div class="des">
			<p>{{$VfaucetCard("费用:Free")}}</p>
			<p>{{$VfaucetCard("奖励:")}}{{rewardInfo.reward_per_num}} WOD
			</p>
			<p>{{$VfaucetCard("CD:24小时")}}</p>
		</div>
		<div class="Daily-Remain">
			<span>{{$VfaucetCard("每日")}}</span>
			<a-tooltip trigger="click" placement="topRight" overlayClassName="Daily-tooltip">
				<template slot="title">{{$VfaucetCard("每日12:00（_f937")}}</template>
				<a-icon type="question-circle" />
			</a-tooltip>
		</div>
		<div class="progress-box">
			<p>
				{{rewardInfo.reward_left_num}}/{{rewardInfo.reward_all_num}}
			</p>
			<a-progress :percent="rewardInfo.reward_left_num/rewardInfo.reward_all_num*100" strokeColor="#3E9FF9">
				<template #format="percent">
					<span></span>
				</template>
			</a-progress>
		</div>
		<div class="btn-box" :class="{'dis':status == 3}">
			<a-button type="primary" @click="addRewardLog" :loading="isLoading">
				{{statusOb[status]}}
			</a-button>
		</div>

		<div class="Faucet-entrance222 md:px-12" v-if="isFaucet">
			<p>{{$VfaucetCard("成功!")}}</p>
			<a-button type="primary" @click="isFaucet = false">
				OK
			</a-button>
		</div>

		<div class="time-box md:px-12" v-if="isTime">
			<a-icon class="iconguanbi" type="close" @click="isTime = false" />
			<div class="time">
				
				<Countdown :nowTime="last_reward_time" :endTime="cur_time" :reward_interval='reward_interval'>
				</Countdown>
			</div>
			<p>{{$VfaucetCard("24小时内只能申_2e92")}}</p>
		</div>
	</div>
</template>

<script>
	import { enable } from "@/web3utils/base/index.js";
	import Countdown from './Countdown.vue'
	import { mapState } from 'vuex'
	import { rewardLog, addRewardLog, checkRewardStatus, checkStatus, RewardStatusReq } from '../../utils/twitter.api.js';
	
    /* viai18n-disable */
    import $VfaucetCardMessages from "./faucetCard.messages.json";
  const $defaultObject = {
		components: {
			Countdown
		},
		name: 'faucetCard',
		computed: {
			...mapState(['locales', 'lang', 'address']),
		},
		data() {
			return {
				isTime: false,
				isFaucet: false,
				isLoading: false,
				status: 0,
				reward_count: 0, //领取次数
				last_reward_time: 0, //领取次数
				reward_interval: 0, //领取间隔时间
				cur_time: 0, //领取次数
				GzStatus: 0,
				statusOb: {
					0: 'Connect Wallet',
					1: 'Claim',
					2: 'Claiming',
					3: 'Without',
				},
				rewardInfo: {
					reward_all_num: 5000,
					reward_left_num: 0,
					reward_num: 0,
					reward_per_num: 0,
					status: 0,
				},
			}
		},
		watch: {
			address(newValue, oldValue) {
				setTimeout(() => {
					this.init();
				}, 200)
			}
		},
		mounted() {
			this.init();
		},
		methods: {
			async init() {
				// if(!)
				this.checkRewardStatus();
				this.rewardLog();
				this.checkStatus();
				this.isTime = false;
				this.isFaucet = false;
			},
			async checkStatus() {
				const { success, data: { status }, code } = await checkStatus({
					addr: this.address,
				})
				this.GzStatus = status || 0;
				if (status <= 0) {
					this.statusOb[0] = 'Connect Social Media'
				} else if (status >= 3) {
					this.statusOb[0] = 'Claim'
				} else {
					this.statusOb[0] = 'Unclaimable'
				}
			},
			async goLogin() {
				//获取登录
				let enables = await enable();
				if (!enables.code) {
					this.$message(this.$VfaucetCard(this.$VfaucetCard("钱包未登录","钱包未登录"),this.$VfaucetCard("钱包未登录","钱包未登录")))
					console.log(this.$VfaucetCard("钱包未登录","钱包未登录"));
					return;
				}
			},
			async rewardLog() {
				const { data } = await rewardLog({
					addr: this.address,
				})
				if (!data) return
				this.rewardInfo = { ...data }
			},
			async checkRewardStatus() {
				if (!this.address) return
				const { data: { status, reward_count, cur_time, last_reward_time, reward_interval } } =
				await checkRewardStatus({
					addr: this.address,
				})
				this.status = status
				this.reward_count = reward_count;
				this.reward_interval = reward_interval;
				this.last_reward_time = last_reward_time;
				this.cur_time = cur_time;
			},
			async addRewardLog() {
				if (this.status == 1) {
					this.isLoading = true;
					setTimeout(async () => {
						const { data: { status } } = await addRewardLog({
							addr: this.address,
						})
						this.isLoading = false;
						this.rewardLog();
						this.checkRewardStatus();
						if (status == 1) {
							this.isFaucet = true;
						}
					}, 500)
				}

				if (this.GzStatus < 3) {
					return this.$router.push('/Setting')
				}
				if (!this.address) {
					return this.goLogin();
				}
				
				if (this.status == 0 && (this.reward_count < this.rewardInfo.reward_num)) {
					await this.checkRewardStatus();
					this.isTime = true;
				}







			},
		},
	}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VfaucetCard(key,origin){
      const messages = $VfaucetCardMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VfaucetCard(key,origin){
      const messages = $VfaucetCardMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>

<style lang="scss" scoped>
	// .modal-enter-active,
	// .modal-leave-active {
	// 	transition: transform 0.3s;
	// }

	// .modal-enter {
	// 	transform: translate(-50%, -50%) scale(0);
	// }

	// .modal-leave-to {
	// 	transform: translate(-50%, -50%) scale(0);
	// }
	.time-box {
		position: fixed;
		top: 50%;
		left: 50%;
		transition: all 1s;
		transform: translate(-50%, -50%);

		background: #0B0B0B;
		padding: 30px;
		border: 1px solid #33C4F2;
		border-radius: 10px;

		width: 420px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-around;
		text-align: center;



		.iconguanbi {
			position: absolute;
			right: 15px;
			top: 15px;
			font-size: 20px;

		}

		p {
			font-size: 16px;
			margin: 0;
		}

		.time {}


		.ant-btn-primary {
			width: 100%;
			height: 100%;
			font-size: 16px;
			color: #fff;
			font-weight: 400;
			padding: 7px 0;
			background: linear-gradient(to right, #203dfc, #33c4f2);
			border: none;
		}

		@screen md {
			width: 307px;

			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);



			.ant-btn-primary {
				padding: 8px 0;
				font-size: 11px;
			}

		}
	}

	.Faucet-entrance222 {
		position: fixed;
		top: 50%;
		left: 50%;
		transition: all 1s;
		transform: translate(-50%, -50%);

		background: #0B0B0B;
		padding: 30px;
		border: 1px solid #33C4F2;
		border-radius: 10px;

		width: 420px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-around;
		text-align: center;






		p {
			font-size: 30px;
			font-weight: bold;
		}

		.ant-btn-primary {
			width: 100%;
			height: 100%;
			font-size: 16px;
			color: #fff;
			font-weight: 400;
			padding: 7px 0;
			background: linear-gradient(to right, #203dfc, #33c4f2);
			border: none;
		}

		@screen md {
			width: 307px;
			height: 139px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			p {
				font-size: 21px;
			}

			.ant-btn-primary {
				padding: 8px 0;
				font-size: 11px;
			}

		}

	}

	.faucetCard {

		width: 329px;
		border: 1px solid #27292D;
		border-radius: 20px;
		transition: all .4s;
		cursor: pointer;

		&:hover {
			border: 1px solid #33C4F2;

			.title {
				border-bottom: 1px solid #33C4F2;
			}
		}

		.title {
			transition: all .4s;
			padding: 10px 0;
			text-align: center;
			color: #fff;
			font-size: 32px;
			border-bottom: 1px solid #27292D;
		}

		.image-box {
			width: 100%;
			text-align: center;

			img {
				display: inline-block;
				width: 56px;
				height: 55px;
			}
		}

		.des {
			padding: 20px 34px 15px;

			p {
				margin-bottom: 12px;
				font-size: 18px;
			}
		}

		.Daily-Remain {
			padding: 0 34px;
			display: flex;
			align-items: center;

			span {
				font-size: 18px;
				margin-right: 15px;
			}


		}

		.progress-box {
			padding: 0px 34px;

			p {
				margin-bottom: 0;
				text-align: right;
				padding-bottom: 5px;
				font-size: 12px;
				color: rgba(255, 255, 255, .6) !important;
			}



		}

		.btn-box {
			margin: 25px 34px;
			height: 39px;
			border-radius: 10px;

			.ant-btn-primary {
				width: 100%;
				height: 100%;
				font-size: 16px;
				color: #fff;
				font-weight: 400;
				background-color: transparent;
				background: linear-gradient(to right, #203dfc, #33c4f2);
				border: none;
				border-radius: 10px;
			}
		}

		.dis {
			.ant-btn-primary {
				background: linear-gradient(to right, #60769A, #585A5D) !important;
			}

		}
	}
</style>
<style>
	.anticon:hover {
		color: rgb(62, 159, 249);
	}

	.Daily-tooltip {
		.ant-tooltip-inner {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 289px !important;
			height: 97px !important;
			border: 1px solid #27292D;
			border-radius: 20px;
			color: rgba(255, 255, 255, .8) !important;
			font-size: 14px;
		}

	}

	.ant-progress {
		.ant-progress-inner {
			background: #27292D;
		}

		.ant-progress-outer {
			padding-right: 0;
		}
	}

	@screen md {
		.Daily-tooltip {
			.ant-tooltip-inner {
				width: auto !important;
				height: auto !important;
			}
		}
	}
</style>